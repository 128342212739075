import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { axios } from 'api'

import { Box, Flex, Image, NameList, P, ResponsiveSVG } from 'ui/acro'
import { CardSubtitle, CardTitle } from '../styles'
import heartSVG from '../../../../../../assets/images/heart.svg'
import heartFilledSVG from '../../../../../../assets/images/heartFilled.svg'

import TimeCodeBadge from './TimeCodeBadge'

const HeartIconBox = styled(Box)`
  svg {
    path {
      &.heart-icon {
        fill: grey;
      }
    }
  }
`

// this version of the class card is used on the Most Popular and New Classes HP carousels
const ClassCard = forwardRef(
  (
    {
      carouselItem,
      humanDuration,
      id,
      plan,
      videoThumbnail,
      title,
      planEntryUrl,
      favoriteCallback,
      ...rest
    },
    ref
  ) => {
    let image
    //const [isFavoritesAvailable, setIsFavoritesAvailable] = useState(rest.favorites?.length)
    const [favoritesId, setFavoritesId] = useState(rest?.favorites?.[0]?.id)
    //let favoritesId = rest?.favorites?.[0]?.id

    console.log('isFavoritesAvailable= ', rest.favorites)
    if (typeof videoThumbnail !== 'undefined') {
      image = videoThumbnail
    }

    const addClass = (id) => {
      return axios
        .post('/favorites', { plan_entry_id: id })
        .then((res) => {
          if (res.status === 200) {
            setFavoritesId(res?.data?.id || null)
            favoriteCallback?.()
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const removeClass = (id) => {
      return axios
        .delete(`/favorites/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setFavoritesId(null)
            favoriteCallback?.()
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }

    return (
      <Flex
        ref={ref}
        id={id}
        flexDirection='column'
        position='relative'
        className={carouselItem ? 'swiper-slide unstyled' : 'unstyled'}
        style={{ minWidth: '198px' }}
        as='a'
        href={planEntryUrl}
      >
        <HeartIconBox
          zIndex={'1'}
          right={['5px', '6px', '16px', '4px']}
          top={['5px', '7px', '35px', '4px']}
          position={'absolute'}
          width={'32px'}
          height={'32px'}
          display={'flex'}
          borderRadius={'50%'}
          alignItems={'center'}
          justifyContent={'center'}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            !favoritesId ? addClass(id) : removeClass(favoritesId)
          }}
          backgroundColor={'#FFFFFF99'}
        >
          <ResponsiveSVG
            SVG={favoritesId ? heartFilledSVG : heartSVG}
            style={{
              cursor: 'pointer'
            }}
            width='24px'
            height='24px'
          />
        </HeartIconBox>
        {image && (
          <Box position='relative' mb={['8px', '10px']}>
            <Image
              src={image}
              objectFit='cover'
              position='absolute'
              top='0'
              left='0'
            />
            <TimeCodeBadge
              fontSize='13px !important'
              bottom='16px !important'
              left='16px !important'
            >
              {humanDuration}
            </TimeCodeBadge>
          </Box>
        )}
        {title && (
          <CardTitle id='SeriesTitle' as='div' paddingBottom='6px'>
            {title}
          </CardTitle>
        )}
        {plan.coaches && (
          <CardSubtitle as='div'>
            <NameList people={plan.coaches} />
          </CardSubtitle>
        )}
        <CardSubtitle as='div'>
          {plan.primaryCategory?.title && plan.primaryCategory.title}
          {plan.subcategory?.title && ` / ${plan.subcategory.title}`}
          {plan.difficultyLevel && ` • ${plan.difficultyLevel}`}
        </CardSubtitle>
        <CardSubtitle
          as='div'
          marginTop='4px'
          color='#758E6D'
          lineHeight='18px !important'
        >
          Series:{' '}
          <a
            style={{
              textDecoration: 'underline',
              color: '#636363'
            }}
            href={`/series/${plan?.slug}`}
          >
            {plan?.title}
          </a>
          {rest?.completedAt ? (
            <P fontSize='14px' color='#758E6D'>
              Completed:{' '}
              {new Date(rest?.completedAt)?.toLocaleString()?.split(',')[0]}
            </P>
          ) : null}
        </CardSubtitle>
      </Flex>
    )
  }
)

ClassCard.propTypes = {
  duration: PropTypes.string,
  id: PropTypes.number,
  plan: PropTypes.object,
  title: PropTypes.string.isRequired,
  videoThumbnail: PropTypes.string.isRequired,
  planEntryUrl: PropTypes.string.isRequired
}

export default ClassCard
