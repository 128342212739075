import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const DropdownContainer = styled.div`
  position: relative;
  width: ${(props) => props.width};
  font-size: 16px;
  color: #000;
`

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  color: #a3a3a3;
  height: ${(props) => props.height};
  gap: 6px;

  @media (max-width: 768px) {
    padding: 2px 16px;
    height: auto;
    border-radius: 360px;
  }
`

const DropdownText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DropdownIcon = styled.div`
  transition: transform 0.3s ease;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const DropdownOptions = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-width: 170px;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 1;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  max-height: 200px;
  overflow-y: auto;

  @media (max-width: 768px) {
    max-height: 150px;
    border: none;
  }
`

const DropdownOption = styled.li`
  padding: 10px;
  cursor: pointer;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};

  &:hover {
    background-color: #e6e6e6;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }
`

const Dropdown = ({
  items,
  value,
  onChange,
  width = '225px',
  height = '48px'
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  // Set initial selected option based on the `value` prop
  useEffect(() => {
    const initialOption = items.find((item) => item.value === value)
    if (initialOption) {
      setSelectedOption(initialOption)
    }
  }, [value, items])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (option) => {
    setSelectedOption(option)
    setIsOpen(false)
    if (onChange) {
      onChange(option)
    }
  }

  return (
    <DropdownContainer width={width}>
      <DropdownHeader height={height} onClick={toggleDropdown}>
        <DropdownText>
          {selectedOption ? selectedOption.label : 'Select an option'}
        </DropdownText>
        <DropdownIcon
          className={isOpen ? 'fa fa-angle-up' : 'fa fa-angle-down'}
        />
      </DropdownHeader>
      <DropdownOptions isOpen={isOpen}>
        {items.map((item) => (
          <DropdownOption
            key={item.uuid}
            onClick={() => handleOptionClick(item)}
            isActive={selectedOption && selectedOption.uuid === item.uuid}
          >
            {item.label}
          </DropdownOption>
        ))}
      </DropdownOptions>
    </DropdownContainer>
  )
}

export default Dropdown
