import { axios } from 'api'
import { useUserContext } from 'context'
import { useEffect } from 'react'
import useGetCountry from './useGetCountry'

const useUpdateCountry = () => {
  const { user } = useUserContext()
  const currentCountry = useGetCountry(false)

  useEffect(() => {
    if (!!user && !!currentCountry) {
      if (user.country != currentCountry) {
        axios
          .put('/users/me', { country: currentCountry })
          .then(() => {})
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }, [user, currentCountry])
}

export default useUpdateCountry
