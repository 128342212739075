import * as Yup from 'yup'
import { axios } from 'api'
import {
  lowercaseCharacterRegex,
  numberCharacterRegex,
  uppercaseCharacterRegex
} from 'components_v2/Subscribe/utils'

const initializeUser = (dispatch, { country }) => {
  return {
    type: 'user',
    loadError: null,
    values: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      consent_marketing_email: country == 'US' ? true : false,
      country: country || null
    },
    validators: {
      firstName: Yup.string()
        .required('Please enter your first name')
        .max(50, 'Please use less than 50 characters'),
      lastName: Yup.string()
        .required('Please enter your last name')
        .max(50, 'Please use less than 50 characters'),
      email: Yup.string()
        .email('Please use a valid email address')
        .required('Please enter your email address'),
      password: Yup.string()
        .required('Please enter your password')
        .min(8, 'Password must be at least 8 characters')
        .matches(
          lowercaseCharacterRegex,
          'Password must include at least 1 lowercase letter'
        )
        .matches(
          uppercaseCharacterRegex,
          'Password must include at least 1 uppercase letter'
        )
        .matches(
          numberCharacterRegex,
          'Password must include at least 1 number'
        ),
      consent_marketing_email: Yup.boolean()
    },
    actions: {
      handleSubmit: async ({ values, setErrors }) => {
        try {
          // Ruby expects the params to be in snake_case, so here we are.
          const res = await axios.post('/users', {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            password: values.password,
            password_confirmation: values.password,
            consent_marketing_email: values.consent_marketing_email,
            country: values.country,
            offer_slug: values?.offerslug
          })
          if (res.status === 200) {
            return res // return res if succesful
          }
        } catch (err) {
          if (err.response && err.response.data && err.response.data.errors) {
            // we need to use setErrors, as error.response.data.errors can be an array, and we need to map it to fields correctly.
            const errors = err.response.data.errors
            setErrors(errors)
          } else {
            throw Error(
              'We apologize, but there has been a problem creating your account. Please try again or contact us at support@alomoves.com for assistance.'
            )
          }
        }
      }
    }
  }
}

export default initializeUser
