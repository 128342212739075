import React, { forwardRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { axios } from 'api'

import { Box, Flex, Image, NameList, ResponsiveSVG } from 'ui/acro'
import ClassCountBadge from './ClassCountBadge'
import { CardSubtitle, CardTitle } from '../styles'

import heartSVG from '../../../../../../assets/images/heart.svg'
import heartFilledSVG from '../../../../../../assets/images/heartFilled.svg'

const HeartIconBox = styled(Box)`
  svg {
    path {
      &.heart-icon {
        fill: grey;
      }
    }
  }
`

const ProductCard = forwardRef(
  (
    {
      carouselItem,
      id,
      classCount,
      workoutCount,
      instructors,
      primaryCategory,
      primarySubcategory,
      thumbnails,
      title,
      slug,
      url,
      introVideoThumbnail,
      thumbnailImage,
      coaches,
      alignBox,
      variantTitle,
      variantSubtitle,
      favoriteCallback,
      subcategory,
      coverPhoto,
      ...rest
    },
    ref
  ) => {
    const FinalclassCount = classCount || workoutCount
    const finalSubcategory = primarySubcategory || subcategory
    const seriesUrl = url || `/series/${slug}/workouts`
    const [favoritesId, setFavoritesId] = useState(
      rest?.planSubscriptions?.[0]?.id
    )
    const defaultImg =
      'https://alomoves.s3.amazonaws.com/manual_uploads/shared/search/placeholder-cards/rectangle-card.jpg'

    const image =
      coverPhoto?.url ||
      (thumbnails && (thumbnails.medium || thumbnails.default)) ||
      (introVideoThumbnail && introVideoThumbnail) ||
      (thumbnailImage === null ? defaultImg : thumbnailImage)

    const removeSeries = (id) => {
      axios
        .delete(`/plan_subscriptions/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setFavoritesId(null)
            favoriteCallback?.()
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const addSeries = (id) => {
      axios
        .post(`/plan_subscriptions`, { plan_id: id })
        .then((res) => {
          if (res.status === 200) {
            setFavoritesId(res?.data?.id || null)
            favoriteCallback?.()
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }

    return (
      <Flex
        ref={ref}
        flexDirection='column'
        alignItems={alignBox}
        position='relative'
        className={carouselItem ? 'swiper-slide unstyled' : 'unstyled'}
        as='a'
        href={seriesUrl}
      >
        {primaryCategory && (
          <HeartIconBox
            zIndex={'1'}
            right={['5px', '6px', '16px', '4px']}
            top={['5px', '7px', '35px', '4px']}
            position={'absolute'}
            width={'32px'}
            height={'32px'}
            display={'flex'}
            borderRadius={'50%'}
            alignItems={'center'}
            justifyContent={'center'}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              !favoritesId ? addSeries(id) : removeSeries(favoritesId)
            }}
            backgroundColor={'#FFFFFF99'}
          >
            <ResponsiveSVG
              SVG={favoritesId ? heartFilledSVG : heartSVG}
              style={{
                cursor: 'pointer'
              }}
              width='24px'
              height='24px'
            />
          </HeartIconBox>
        )}
        {image && (
          <Box position='relative' mb={['8px', '10px']}>
            <Image
              src={image}
              objectFit='cover'
              position='absolute'
              top='0'
              left='0'
            />
            {FinalclassCount > 0 && (
              <ClassCountBadge
                bottom='16px !important'
                left='16px !important'
              >{`${FinalclassCount} Class${
                FinalclassCount <= 1 ? '' : 'es'
              }`}</ClassCountBadge>
            )}
          </Box>
        )}
        {title && (
          <CardTitle
            id='SeriesTitle'
            as='div'
            paddingBottom='6px'
            variant={variantTitle}
          >
            {title}
          </CardTitle>
        )}
        {instructors && (
          <CardSubtitle as='div'>
            <NameList people={instructors || coaches} />
          </CardSubtitle>
        )}
        {coaches && (
          <CardSubtitle as='div' variant={variantSubtitle}>
            <NameList people={coaches} />
          </CardSubtitle>
        )}

        <CardSubtitle as='div'>
          {primaryCategory && (primaryCategory.name || primaryCategory.title)}
          {finalSubcategory &&
            (finalSubcategory.name
              ? ` / ${finalSubcategory.name}`
              : ` / ${finalSubcategory.title}`)}
        </CardSubtitle>
      </Flex>
    )
  }
)

ProductCard.propTypes = {
  duration: PropTypes.string,
  instructors: PropTypes.array,
  id: PropTypes.string,
  primaryCategory: PropTypes.object,
  primarySubcategory: PropTypes.object,
  title: PropTypes.string.isRequired,
  thumbnails: PropTypes.object,
  coverPhoto: PropTypes.array,
  coaches: PropTypes.array,
  alignBox: PropTypes.string,
  variantSubtitle: PropTypes.string,
  variantTitle: PropTypes.string,
  rest: PropTypes.object
}

export default ProductCard
